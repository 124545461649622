import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import ContentfulRichText from '../components/contentfulRichText';
import Layout from '../components/layout';
import { Seo } from '../components/seo';

const SeeTemplate = ({ data }) => {
  return (
    <Layout
      isTemplate={true}
      templateTitle={data.allContentfulSee.nodes[0].title}
    >
      <main className="template">
        <header className="template-header">
          <div className="template-content">
            <h1 className="block mb-4 md:hidden md:mb-0">
              {data.allContentfulSee.nodes[0].title}
            </h1>
            <ContentfulRichText
              richText={data.allContentfulSee.nodes[0].description}
            />

            <Link className="template-button" to={`/hello`}>
              Book an appointment
            </Link>
          </div>

          <div className="template-hero">
            <GatsbyImage
              alt={data.allContentfulSee.nodes[0].featuredImage.gatsbyImage}
              image={data.allContentfulSee.nodes[0].featuredImage.gatsbyImage}
              className="absolute inset-0"
            />
          </div>
        </header>

        <section className="template-images">
          {data.allContentfulSee.nodes[0].images.map((image, index) => (
            <div key={index}>
              <GatsbyImage
                alt={image.title}
                image={image.gatsbyImage}
                className="template-image"
              />
            </div>
          ))}
        </section>
      </main>
    </Layout>
  );
};

export default SeeTemplate;

export const Head = ({ data }) => (
  <Seo
    title={data.allContentfulSee.nodes[0].meta.title}
    description={data.allContentfulSee.nodes[0].meta.description}
  />
);

export const query = graphql`
  query collectionQuery($contentful_id: String!) {
    allContentfulSee(filter: { contentful_id: { eq: $contentful_id } }) {
      nodes {
        contentful_id
        title
        slug
        featuredImage {
          gatsbyImage(width: 1200)
          title
        }
        description {
          raw
        }
        images {
          gatsbyImage(width: 800)
          title
        }
        meta {
          title
          description
        }
      }
    }
  }
`;
